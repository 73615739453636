<template>
  <div class="container">
    <!-- <div class="box">
      <div class="title">您认为智游乐山微信端最满意的方面（可多选）</div>
      <div class="content">
        <a href="javascript:;" class="checked">出现便捷</a>
        <a href="javascript:;">出游方便</a>
        <a href="javascript:;">资讯全面</a>
        <a href="javascript:;">预订体验</a>
        <a href="javascript:;">产品丰富</a>
        <a href="javascript:;">优惠力度</a>
      </div>
    </div> -->
    <div class="box">
      <div class="title">反馈意见</div>
      <div class="content"><textarea v-model="text"></textarea></div>
    </div>
    <div class="box">
      <div class="title">上传图片</div>
      <div class="content">
        <div class="imgbox" v-for="(item,index) in imgList" :key="index">
          <div class="remove" @click="remove(item)">X</div>
          <img :src="item.filePath" alt="" />
        </div>
        <label for="file">
          <input id="file" type="file" @change="handlerImage" ref="inputer" />
        </label>
        <!-- <label for="android" v-if="isAndroid">
          <input id="android" type="file" @change="handlerImage" ref="inputer" accept="image/*" data-id='an' />
        </label>
        <label for="ios" v-else >
          <input id="ios" type="file" @change="handlerImage" ref="inputer" accept="image/*;capture=user" data-id='ios' />
        </label> -->
      </div>
    </div>
    <a href="javascript:;" class="btn" @click="submit">确认提交</a>
  </div>
</template>
<script>
  import { Toast } from 'vant'
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
  export default {
    name: 'Feedback',
    data () {
      return{
        urls: {
          upload: 'upload', // 头像上传
          insertUserSuggestion: 'userSuggestion/insertUserSuggestion' // 意见反馈
        },
        isAndroid: false, // 检测设备是否为安卓
        text: '', // 反馈意见
        imgList: [], // 已上传图片列表
        loading: false // 数据提交中
      }
    },
    created(){

      this.getShareInfo(
        this.$global.shareTitle,
        this.$global.visitUrl,
        this.$global.shareImage,
        this.$global.shareIntro,
      );
    },
    mounted(){
      console.log('意见反馈')
      this.isAndroid = isAndroid
    },
    methods: {
      // 图片上传
      handlerImage(){
        let that = this;
        let file = this.$refs.inputer.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          let content = this.result;
          if(content){
            let img = new Image();
            img.src = content;
            img.onload = function (){
              let q = file.size > 64*1024 ? 64*1024/file.size : 1;
              that.compressEvent(img, q,(dataURL)=> {
                that.uploadImg(dataURL);
              })
            }
          }
        };
      },
      // 压缩并转码
      compressEvent(img, q, callback){
        let canvas = document.createElement('canvas');
        let ctx = canvas.getContext('2d');
        // let width = img.width;
        // let height = img.height;
        let width = 960;
        let height = img.height * 960 / img.width;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        let dataURL = canvas.toDataURL("image/jpeg", q);
        callback ? callback(dataURL):null;
      },
      // 将base64转为文件
      dataURLtoFile(dataurl, filename) { //将base64转换为文件
        var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {
          type: mime
        });
      },
      // 提交图片
      uploadImg(base64) {
        const me = this;
        // var imageBase64 = base64.split(';base64,')[1]
        var file = this.dataURLtoFile(base64, 'avatar.jpg')
        var params = new FormData()
        params.append('file', file)
        this.$post({
          url: this.urls.upload,
          params: params
        }).then(rsp => {
          console.log(rsp)
          me.imgList = me.imgList.concat(rsp.data)
        })
      },
      // 删除图片
      remove(item) {
        let temporary = [];
        let i;
        for(i in this.imgList){
          if(item == this.imgList[i]){
            continue
          }
          temporary.push(this.imgList[i])
        }
        this.imgList = temporary
      },
      // 提交数据
      submit() {
        if(this.loading) return
        if(this.text.replace(/\s/g, '') == ''){
          Toast('请输入反馈意见')
          return
        }
        this.loading = true
        let i, fileIds = [], me = this;
        for(i in this.imgList){
          fileIds.push(this.imgList[i].fileId)
          fileIds.push(',')
        }
        fileIds = fileIds.join('')
        fileIds = fileIds.substr(0, fileIds.length -1)
        console.log(fileIds)
        this.$post({
          url: this.urls.insertUserSuggestion,
          params: {
            token: this.$global.token,
            content: this.text,
            fileIds: fileIds
          }
        }).then(rsp => {
          console.log(rsp)
          me.loading = false
          Toast('提交成功！感谢您的反馈与支持。')
        }).catch( error => {
          console.log(error)
          me.loading = false
          Toast(error.message)
        })
      }
    }
  }
</script>
<style scoped lang="scss">
  .container{
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding: 0 20px 20px 20px;
    box-sizing: border-box;
    .box{
      width: 100%;
      display: inline-block;
      margin-top: 15px;
      .title{
        font-size: 16px;
        line-height: 30px;
        font-weight: bold;
        color: #333;
      }
      .content{
        a {
          font-size: 14px;
          line-height: 20px;
          padding: 5px 15px;
          border-radius: 15px;
          background: #f1f1f1;
          color: #333;
          display: inline-block;
          margin: 0 10px 5px 10px;
        }
        a.checked{
          color: #fff;
          background: linear-gradient(to right, #7ddb4f, #21dd53);
        }
        textarea{
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #e1e1e1;
          border-radius: 3px;
          min-height: 100px;
          resize: none;
          padding: 10px;
          line-height: 20px;
          font-size: 15px;
          margin-top: 10px;
          -webkit-appearance: none;
          appearance: none;
        }
        .imgbox{
          width: 100px;
          height: 100px;
          display: inline-block;
          vertical-align: middle;
          margin: 10px 15px 0 0;
          position: relative;
          .remove{
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 3px 0 0;
            background: rgba(0, 0, 0, .5);
            color: #fff;
            font-size: 14px;
            width: 20px;
            height: 20px;
            text-align: center;
            line-height: 20px;
          }
          img{
            width: 100px;
            height: 100px;
            border: 1px solid #e1e1e1;
            border-radius: 3px;
            font-size: 0;
            box-sizing: border-box;
          }
        }
        label {
          display: inline-block;
          width: 100px;
          height: 100px;
          border: 1px solid #e1e1e1;
          border-radius: 3px;
          font-size: 0;
          box-sizing: border-box;
          vertical-align: middle;
          background: url(../assets/img/icon-img.png) no-repeat center center/auto 40%;
          margin-top: 10px;
          cursor: pointer;
          input{
            font-size: 0;
            border: none;
            background: none;
            opacity: 0;
          }
        }
      }
    }
    a.btn{
      position: fixed;
      width: 100%;
      height: 50px;
      line-height: 50px;
      left: 0;
      bottom: 0;
      font-size: 18px;
      font-weight: bold;
      color: #fff;
      text-align: center;
      background: linear-gradient(to right, #7ddb4f, #21dd53);
    }
  }
</style>
